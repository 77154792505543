@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-SemiBold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-Regular.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-Medium.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-ExtraLight.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-Bold.ttf");
  font-weight: 800;
}

.text-error {
  color: #ec1c24 !important;
  font-weight: 500 !important;
  font-size: 0.8rem !important;
}

@font-face {
  font-family: "ZahraArabic";
  src: url(../src/fonts/ar/18618-zahra.arabic.otf);
}
@font-face {
  font-family: "ZahraArabicBold";
  src: url(../src/fonts/ar/ZahraArabic-Bold.otf);
}