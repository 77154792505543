.LoginContent {
  min-height: 100vh;
  background: linear-gradient(
    90deg,
    rgba(247, 246, 247, 1) 0%,
    rgba(185, 185, 185, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  .LoginLogoContent {
    h5 {
      color: #555555;
      font-family: "Montserrat" !important;
      font-weight: 800;
      font-size: 1.4rem;
    }
    p {
      color: #ed1c24;
      font-family: "Montserrat" !important;
      font-weight: 600 !important;
      span {
        font-weight: 900;
      }
    }
    .fitRewardsLogo {
      margin-top: 35px;
      width: 400px;
      height: auto;
    }
  }
  .LoginFormContent {
    h6 {
      text-align: center;
      color: #ffffff;
      font-weight: 700;
      font-size: 1.3rem;
    }
    .inputIcon {
      background-color: #df4d53 !important;
      border: none !important;
      color: #8e0f14 !important;
      border-radius: 15px 0px 0px 15px !important;
    }
    .inputField {
      background-color: #df4d53 !important;
      border: none !important;
      color: whitesmoke !important;
      font-weight: 600 !important;
      font-size: 0.9rem !important;
      height: 40px !important;
      border-radius: 0px 15px 15px 0px !important;
      padding-left: 25px !important;
      &:focus {
        box-shadow: none !important;
        color: #f4f3f4 !important;
        font-size: 0.8rem;
        font-weight: 700;
      }
      &::placeholder {
        color: gainsboro;
        font-weight: 600;
        font-size: 0.8rem;
      }
    }
    .rememberCheck {
      color: #555555;
      .form-check-input {
        background-color: #8e0f14 !important;
      }
    }
    .rememberLabel {
      font-weight: 600 !important;
      color: #ffffff;
    }
  }
}
