:root {
  --toastify-color-error: whitesmoke;
  --toastify-color-success: whitesmoke;
  --toastify-spinner-color: whitesmoke;
}
.Toastify__toast {
  font-family: "IBMPlexSansArabic" !important;
  font-size: 0.8rem !important;
  font-weight: 800 !important;
  font-style: italic !important;
}
.Toastify__toast-theme--light.Toastify__toast--default {
  background: radial-gradient(
    circle,
    rgba(183, 51, 47, 1) 0%,
    rgba(129, 6, 2, 1) 100%
  );

  color: #f5f5f5 !important;
}

.Toastify__toast--rtl {
  font-family: "ZahraArabicBold" !important;
  font-size: 1.2rem !important;
}

.Toastify__toast-theme--light.Toastify__toast--error {
  background: linear-gradient(
    90deg,
    rgba(255, 142, 158, 1) 0%,
    rgba(255, 189, 158, 1) 100%
  );

  color: whitesmoke !important;
}
.Toastify__toast-theme--light.Toastify__toast--success {
  background: linear-gradient(
    90deg,
    rgba(156, 220, 120, 1) 0%,
    rgba(120, 220, 173, 1) 100%
  );
  color: whitesmoke !important;
}
