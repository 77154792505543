.dashboard {
  min-height: 100vh;
  background: linear-gradient(
    90deg,
    rgba(247, 246, 247, 1) 0%,
    rgba(185, 185, 185, 1) 100%
  );
}

.reportParent {
  background-color: #ffffff;
  border-radius: 15px;
  .reportTitle {
    h4 {
      color: #000000;
      font-size: 1.8rem;
      font-weight: 700;
    }
  }
  .reportContent {
    background-color: #f7f7f7;
    .reportTotalEgifts {
      background-color: #c1c1c1;
      border-radius: 10px;
    }
    .reportRemainEgifts {
      background-color: #ffffff;
      border-radius: 10px;
    }
  }
}
