


.receiptPhoto {
  width: 100%;
  height: 300px !important;
  background-color: gainsboro;
  border-radius: 10px;
  object-fit: contain;
}

.emptyField {
  width: 100%;
  height: 300px !important;
  background-color: gainsboro;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyField > svg {
  font-size: 8rem !important;
  color: #d3d3d3;
}

.uploadBtn,
.captureBtn {
  font-family: "IBMPlexSansArabic"!important;
  font-size: 1rem;
}
