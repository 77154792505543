.giftsContent {
  width: 100%;
  min-height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  .barcodeContent {
    background-color: #ececec;
    border-radius: 15px;
    .barcodeGift {
      width: auto;
      height: 120px;
      object-fit: contain;
      background-color: white;
      padding: 15px;
    }
  }
}

.warningIcon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.warningModalContent {
  color: black;
  border: 1px solid black;
  border-radius: 10px;
  h6 {
    font-size: 1.9rem !important;
    font-weight: 600;
    color: #b7332f;
  }
  ul {
    list-style-type: none;
    li {
      font-size: 1.2rem;
      .numberElement{
        color: #b7332f;
        font-size: 1.1!important;
        font-weight: 700;
        font-family: "Montserrat","IBMPlexSansArabic"!important;
      }
      img {
        max-width: 130px;
      }
    }
  }
  .warningBtn {
    color: #b7332f;
    font-size: 1.2rem;
    font-weight: 700;
  }
}
.barcodeTitle {
  font-family: "Montserrat","IBMPlexSansArabic"!important;
  font-weight: 700!important;
  font-size: 1.1rem!important;
}
