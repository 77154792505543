.layout {
  font-family: "IBMPlexSansArabic", "Montserrat" !important;
  font-size: 20px !important;
}
.layout {
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: end;
  flex-direction: column;
}
.layoutHeader {
  min-height: 150px;
}
.layoutBody {
  min-height: calc(100vh - 600px);
}
.layoutFooter {
  min-height: 80px;
}
.fitRewardsLogo {
  width: 100%;
  max-width: 200px;
  height: auto;
  object-position: center;
  object-fit: contain;
}
.shareecoinLogoWhite {
  max-width: 150px;
  height: auto;
  object-position: center;
  object-fit: contain;
}
div:has(> .fitRewardsLogo) {
  display: flex;
  align-items: center;
  justify-content: start;
}

@media (max-width: 785px) {
  div:has(> .fitRewardsLogo) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

div:has(> .shareecoinLogoWhite) {
  display: flex;
  align-items: flex-end;
  justify-content: end;
}

.loadPage {
  position: absolute;
  top: 0px;
  left: 0px;
  background: radial-gradient(
    circle,
    rgba(183, 51, 47, 1) 0%,
    rgba(129, 6, 2, 1) 100%
  );
  color: whitesmoke !important;
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
