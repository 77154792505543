.searchTrainer * {
  font-family: "IBMPlexSansArabic" !important;
}

.searchTrainer {
  min-height: 100vh;
  background: linear-gradient(
    90deg,
    rgba(247, 246, 247, 1) 0%,
    rgba(185, 185, 185, 1) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .searchChar {
    width: 250px;
    height: auto;
    object-fit: contain;
    margin-bottom: 35px;
  }
  .inputField {
    text-align: center;
    font-family: "IBMPlexSansArabic", "Montserrat" !important;
    background-color: #df4d53 !important;
    border: none !important;
    color: whitesmoke !important;
    font-weight: 600 !important;
    font-size: 0.9rem !important;
    height: 40px !important;
    border-radius: 15px !important;
    &:focus {
      text-align: center;
      font-family: "IBMPlexSansArabic", "Montserrat" !important;
      box-shadow: none !important;
      color: #f4f3f4 !important;
      font-size: 0.8rem;
      font-weight: 700;
    }
    &::placeholder {
      text-align: center;
      font-family: "IBMPlexSansArabic", "Montserrat" !important;
      color: gainsboro;
      font-weight: 600;
      font-size: 1.2rem;
    }
  }
  .searchBtn {
    font-size: 1.2rem;
  }
}
.layoutFooter {
  min-height: 80px;
}
.shareecoinLogoWhite {
  width: 150px;
  height: auto;
  object-position: center;
  object-fit: contain;
}

div:has(> .shareecoinLogoWhite) {
  display: flex;
  align-items: flex-end;
  justify-content: end;
}
