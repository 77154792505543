.infoContent {
  width: 100%;
  min-height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  .customerInfo {
    background-color: #ececec;
    border-radius: 10px;
  }
}
.bgWhite {
  background-color: #ffffff;
  border-radius: 10px;
  .emailInput {
    background-color: #ececec;
    border: none;
    outline: none;
    border-radius: 20px;
    color: rgb(95, 94, 94);
    font-size: 0.8rem;
    text-align: center;
    font-weight: 800 !important;
    font-family: "Montserrat" !important;
    &::placeholder {
      color: #ffffff;
    }
    &:focus {
      box-shadow: none;
      outline: none;
      border: none;
    }
  }
  .customerInput {
    background-color: #ececec;
    border: none;
    outline: none;
    border-radius: 20px;
    color: rgb(95, 94, 94);
    font-size: 0.8rem;
    text-align: center;
    font-weight: 800 !important;
    font-family: "Montserrat","adoody"!important;
    &::placeholder {
      color: #ffffff;
    }
    &:focus {
      box-shadow: none;
      outline: none;
      border: none;
    }
  }
}

.genderLabel {

  border-radius: 20px;
  background-color: #ececec;
  border: none;
  color: #ffffff;
  display: flex;
  min-width: 100px;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  cursor: pointer;
  .genderIcon {
    width: 20px;
    height: 20px;
  }
}

.genderInput:checked + label{
  background-color: #ffffff;
  color: gray;
  border: 2px solid gray;
}


.submitBtn {
  font-size: 1.3rem;
}

@media(max-width : 400px){
  .emailInput{
    font-size: 0.6rem!important;
  }
}