.chooseGift {
  width: 100%;
  background-color: #ececec;
  border-radius: 10px;
}
.fontNum {
  font-family: "Montserrat" !important;
  font-size: 1.2rem !important;
  font-weight: 900 !important;
  font-style: italic;
}
.giftChooseValue {
  font-family: "Montserrat" !important;
  font-size: 1.2rem !important;
  font-weight: 900 !important;
  border: 2px solid black;
  border-radius: 20px;
}
.giftChooseValue > span {
  font-size: 0.9rem;
  font-weight: 500;
}
.giftContent {
  background-color: white;
  border-radius: 10px;
}
.giftInput:checked + label{
  background-color: #cccccc;
}
.giftInput:checked + label > div {
  color: black;
}
.giftInput:checked + label > div:has(> .fontNum)  {
  color: black;
  border: 2px solid black;
}

.giftElement {
  margin-left: 10px;
  margin-right: 10px;
  min-width: 160px;
  label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: whitesmoke;
    text-align: center;
    border-radius: 15px;
    border: 2px solid #b7b7b7;
    .giftIconStyle {
      font-size: 1.4rem !important;
      color: #b3b3b3;
    }
  }
}
div:has(> .fontNum) {
  font-size: 0.7rem !important;
  border: 2px solid #b3b3b3;
  border-radius: 20px;
  padding: 3px 10px;
  margin-bottom: 10px;
  font-weight: 500;
  color: #b3b3b3;
}


@media (max-width: 356px) {
  .giftElement:first-child {
    margin-bottom: 10px;
  }
}
