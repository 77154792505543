.homeBackground {
    display: flex;
    align-items: center;
  .customerHome {
    background-color: #ffffff;
    border-radius: 15px;
  }
  .mobileNumber {
    outline: none;
    border: 1px solid #b7332f;
    text-align: center;
    font-family: "Montserrat","IBMPlexSansArabic"!important;
    &:focus {
      outline: none;
      box-shadow: none;
      border: 3px solid #b7332f;
      font-weight: 700 !important;
    }
  }
}
