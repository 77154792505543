.services {
  border: 2px solid gainsboro;
  border-radius: 0px 15px 15px 0px;
  max-height: 215px;
  scrollbar-color: #f5f5f5 #b71216;
  scrollbar-width: 10px;
  overflow: overlay;
  .serviceContent {
    min-width: 120px;
    min-height: 180px;
    .serviceLabel {
      padding: 10px;
      min-width: 110px;
      min-height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 5px;
      background-color: #ececec;
      border-radius: 10px;
      cursor: pointer;
      .servicePic {
        width: 90px;
        height: 90px;
        object-fit: contain;
        border-radius: 10px;
        margin-bottom: 5px;
        background-color: white;
        padding: 5px;
      }
      .serviceText {
        font-size: 0.8rem !important;
        font-weight: 600;
        color: gray;
        text-align: center;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b71216;
  }
}

.serviceInput:checked + label{
  background-color: white!important;
  border: 2px solid gray!important;
}

.serviceInput:disabled + label{
  opacity: 0.4;
  cursor: not-allowed!important;
}