input[type="text"] {
  font-family: "Montserrat" !important;
}

.ModalBodyOTP {
  font-family: "IBMPlexSansArabic" !important;
  font-size: 18px !important;
}

.digitsParent {
  background-color: #e4e4e4;
  border-radius: 20px;
  .otpInput {
    border: 2px solid darkred;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
    text-align: center;
    font-weight: 700;
    font-family: "Montserrat","IBMPlexSansArabic"!important;
  }
}

.nextBtn {
  font-size: 22px !important;
}

div:has(> .otpInput) {
  max-width: 70px;
  min-width: 33px;
  margin: 0px;
  padding: 3px;
}

.errorInput{
  border: 3px solid #ec1c24!important;
  transition: all 0.2s;
}