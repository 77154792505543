.congratsContent {
  width: 100%;
  min-height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  .giftPic {
    width: 150px;
    height: auto;
    object-fit: contain;
  }
  .giftMessage {
    text-align: center;
    background-color: #ececec;
    border-radius: 15px;
    h5 {
      font-weight: 800;
      font-size: 1.1rem;
      font-weight: 800;
    }
    p {
      margin-top: 5px;
      font-family: "Montserrat" !important;
      font-size: 1rem !important;
      font-weight: 700;
      font-style: italic;
      color: grey;
    }
    .giftCongrats {
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
      border-radius: 10px;
      background-color: white;
      padding: 10px;
      object-fit: contain;
    }
    .changeGift {
      min-width: 200px;
      font-size: 24px;
      border-radius: 15px;
    }
  }
  .captureReceipt {
    border: 1px solid gray;
    border-radius: 10px;
  }
}
.showReceipt {
  text-align: center;
  border: 1px solid gray;
  border-radius: 15px;
  .receiptIcon {
    font-size: 120px !important;
    color: gainsboro;
    opacity: 0.5;
  }
}
.receiptPic {
  width: 100% !important;
  height: 100%;
  max-height: 160px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ede9e9;
  border-radius: 5px;
}
div:has(> .giftPic) {
  text-align: center;
}

.receiptNotes {
  ul {
    li {
      color: #b7332f;
      list-style-type: none;
      span {
        display: block;
        font-weight: 700;
      }
      &::before {
        margin-left: 10px;
        background-image: url("../../../imgs/warning.png");
        background-size: 20px 20px;
        display: inline-block;
        width: 20px;
        height: 20px;
        content: "";
      }
    }
  }
}

.sendBtns {
  button {
    font-size: 1.2rem;
    border-radius: 10px;
    min-width: 129px;
  }
}

.warningIcon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.warningModalContent {
  color: black;
  border: 1px solid black;
  border-radius: 10px;
  h6 {
    margin: 10px;
    font-size: 1.2rem !important;
    font-weight: 800;
    color: #b7332f;
    font-family: "Montserrat", "IBMPlexSansArabic" !important;
  }
  ul {
    list-style-type: none;
    li {
      font-size: 1rem;
      .numberElement {
        color: #b7332f !important;
        font-size: 0.8 !important;
        font-weight: 700;
        font-family: "Montserrat", "IBMPlexSansArabic-Bold" !important;
      }
      img {
        max-width: 120px!important;
        height: auto;
      }
    }
  }
  .warningBtn {
    color: #b7332f;
    font-size: 1.2rem;
    font-weight: 700;
  }
}

.giftValueStyle {
  font-size: 1.1rem !important;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  background-color: red;
  color: white !important;
  font-family: "Montserrat", "IBMPlexSansArabic" !important;
}
